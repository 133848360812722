<template>
  <div>
    <div class="home p-6 rounded-lg md:max-w-7xl md:mx-auto">
      <let-it-snow
        :show="snow"
        v-bind="{
          windPower: 0,
          speed: 1,
          count: 35,
          size: 2,
          opacity: 0.2,
        }"
      ></let-it-snow>

      <h2 class="font-bold text-3xl text-xmas-red mb-4 mt-4 relative">
        <img
          src="/img/holly.svg"
          class="w-10 h-10 absolute left-0 top-0 -mt-5 -ml-4"
        />
        Christmas {{ $date().format("YYYY") }}
      </h2>

      <p class="text-white mb-8 text-lg" style="font-family: sans-serif">
        May this Christmas bring us all the joy, happiness and good health that
        the world has to offer!
      </p>

      <div class="grid grid-cols-4 mb-5 gap-2 text-center z-10 relative">
        <div class="bg-xmas-brown p-4 rounded-lg">
          <span class="text-xl text-white block">{{ days }}</span>
          <span class="text-xs text-gray-400">Days</span>
        </div>

        <div class="bg-xmas-brown p-4 rounded-lg">
          <span class="text-xl text-white block">{{ hours }}</span>
          <span class="text-xs text-gray-400">Hrs</span>
        </div>

        <div class="bg-xmas-brown p-4 rounded-lg">
          <span class="text-xl text-white block">{{ mins }}</span>
          <span class="text-xs text-gray-400">Mins</span>
        </div>
        <div class="bg-xmas-brown p-4 rounded-lg">
          <span class="text-xl text-white block">{{ secs }}</span>
          <span class="text-xs text-gray-400">Secs</span>
        </div>
      </div>
      <div class="grid grid-cols-3 md:grid-cols-9 gap-4 z-10">
        <day-item
          @play="playSparkle"
          :day="day"
          :index="index"
          v-for="(day, index) in advents"
          :key="day.id"
        />
      </div>

      <p class="text-center mt-10 text-white">Made with 💖 by Aaron</p>
    </div>
  </div>
</template>

<script>
import { Howl } from "howler";
import DayItem from "@/components/day-item";
export default {
  name: "Home-view",
  components: { DayItem },
  created() {
    this.getAdvents();

    this.sparkle = new Howl({
      src: ["merry.wav"],
    });

    this.stab = new Howl({
      src: ["stab.wav"],
    });
    this.prize = new Howl({
      src: ["victory.mp3"],
    });

    this.setCountdown();

    setInterval(() => {
      this.setCountdown();
    }, 1000);
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API;
    },
    xmasDay() {
      return this.$date().endOf("year").subtract(6, "day").startOf("day");
    },
  },
  data() {
    return {
      advents: [],
      snow: false,
      sparkle: false,
      stab: false,
      prize: false,
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0,
    };
  },
  methods: {
    setCountdown() {
      var totalSeconds = this.xmasDay.diff(this.$date(), "second");

      const seconds = Math.floor(totalSeconds % 60);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      const days = Math.floor(totalSeconds / (3600 * 24));

      this.days = days;
      this.hours = hours;
      this.mins = minutes;
      this.secs = seconds;
    },
    playSparkle($day) {
      if ($day.redeemable) {
        this.sparkle.play();
        this.prize.play();
      } else {
        this.sparkle.play();
        this.stab.play();
      }
    },
    getAdvents() {
      this.$http.get(this.apiUrl + "/advent").then((response) => {
        this.advents = response.data;
        this.snow = true;
      });
    },
  },
};
</script>

<style lang="less">
.snow-wrap {
  z-index: 0 !important;
}
.swal2-title {
  font-family: landa, sans-serif;
  line-height: 1.5;
  font-size: 1.5rem !important;
  color: white !important;
}
.swal2-confirm {
  background: #b8c7b0 !important;
  box-shadow: 0 0 0 0px fade(white, 40) !important;
  color: #565e51 !important;
  &:focus {
    box-shadow: 0 0 0 0px fade(white, 40) !important;
  }
}
.swal2-popup {
  background: transparent !important;
  width: 20em !important;
}
.swal2-container {
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.8) !important;
}
.swal2-content {
  color: white !important;
  line-height: 1.5 !important;
}
.swal2-image {
  border-radius: 10px !important;
  border: 6px solid #fff !important;
}
</style>
