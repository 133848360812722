import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';
import dayjs from 'dayjs'
import 'reset-css';
import VueSweetalert2 from 'vue-sweetalert2';


import './assets/css.css'


import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import VueDayjs from 'vue-dayjs-plugin'
Vue.use(VueDayjs)
Vue.use(VueSweetalert2)
dayjs.extend(AdvancedFormat)

import LetItSnow from 'vue-let-it-snow';
import './assets/tailwind.css'
Vue.use(LetItSnow);

Vue.prototype.$http = axios;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
