<template lang="html">
  <div class="cursor-pointer aspect-w-1 aspect-h-1 rounded-lg text-center relative overflow-hidden" :class="{[bg]:!opened,'col-span-3 md:col-span-1':index == 24}" @click.prevent="clickDay" :data-id="$date(day.day).format('D')">
      <div class="inner flex items-center justify-center text-3xl" v-if="!opened">
          

          <img  class="w-10 h-10 " :src="'/img/xmasicons/'+(index+1)+'.svg'" />
          <span class="absolute text-base bottom-0 right-0 mr-2 mb-1">{{$date(day.day).format('D')}}</span>
      </div>

      <div v-show="opened">
        <span class="imagesurround">
          <img  class="w-full rounded-lg aspect-h-1"  :src="'/img/days/'+$date(day.day).format('D')+'.jpg'" alt="">
        </span>

      </div>   
</div>
</template>

<script>
export default {
  name: "day-item",
  props: ["day", "index"],
  computed: {
    isBefore() {
      var todaysDate = this.$date();
      // var todaysDate = this.$date("2022-12-25 00:00:01"); // Used for testing
      return this.todaysCalendarDate.isBefore(todaysDate);
    },
    todaysCalendarDate() {
      return this.$date(this.day.day, "YYYY-mm-dd HH:mm:ss");
    },
    daysToGo() {
      var xmas = this.$date().endOf("year").subtract(6, "day").startOf("day");
      return xmas.diff(this.todaysCalendarDate, "day");
    },
    apiUrl() {
      return process.env.VUE_APP_API;
    },
    bg() {
      return this.bgs[this.index];
    },
  },
  data() {
    return {
      opened: false,
      bgs: [
        "striped",
        "striped2",
        "plain",
        "dotted",
        "plain2",
        "zigzag",
        "striped",
        "striped2",
        "plain",
        "dotted",
        "plain2",
        "zigzag",
        "striped",
        "striped2",
        "plain",
        "dotted",
        "plain2",
        "zigzag",
        "striped",
        "striped2",
        "plain",
        "dotted",
        "plain2",
        "zigzag",
        "striped",
      ],
    };
  },
  created() {
    this.opened = this.day.opened;
  },
  methods: {
    clickDay() {
      if (!this.isBefore) {
        this.$swal.fire({
          title: "Oiii no peaking!",
          confirmButtonText: "Close",
          text:
            "you're not allowed to open this until the " +
            this.$date(this.day.day).format("Do MMMM"),
        });
      } else if (!this.opened) {
        this.opened = true;
        this.$emit("play", this.day);
        this.markOpened();
        this.$swal.fire({
          title: this.daysToGo + " days to go!",
          text: this.day.info,
          confirmButtonText: "Close",
          imageUrl:
            "/img/days/" + this.$date(this.day.day).format("D") + ".jpg",
        });
      } else {
        this.$swal.fire({
          title: this.daysToGo + " days to go!",
          text: this.day.info,
          confirmButtonText: "Close",
          imageUrl:
            "/img/days/" + this.$date(this.day.day).format("D") + ".jpg",
        });
      }
    },
    markOpened() {
      this.$http.post(this.apiUrl + "/advent/" + this.day.id);
    },
    redeeem() {
      this.$http.post(
        this.apiUrl +
          "/advent/" +
          this.$date(this.day.day).format("D") +
          "/redeem"
      );
    },
  },
};
</script>

<style lang="css" scoped>
.striped {
  background: repeating-linear-gradient(
    45deg,
    #f9f0d8,
    #f9f0d8 10px,
    #dfc19a 10px,
    #dfc19a 20px
  );
}
.striped2 {
  background: repeating-linear-gradient(
    45deg,
    #b7383d,
    #b7383d 10px,
    #9c2c2b 10px,
    #9c2c2b 20px
  );
  color: #f5eed8;
}
.plain {
  background: #f5eed8;
}
.plain2 {
  background: #b8c7b0;
}

.dotted {
  background: #b7383d;
  background-image: radial-gradient(#9c2c2b 3px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;
  color: #f5eed8;
}
.zigzag {
  background-color: #b8c7b0;
  background-image: linear-gradient(135deg, #93a992 25%, transparent 25%),
    linear-gradient(225deg, #93a992 25%, transparent 25%),
    linear-gradient(315deg, #93a992 25%, transparent 25%),
    linear-gradient(45deg, #93a992 25%, transparent 25%);
  background-position: -30px 0, -30px 0, 0 0, 0 0;
  background-size: 20px 20px;
  background-repeat: repeat;
}
</style>
